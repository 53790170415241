Px.CMS.FileUpload = class FileUpload extends HTMLElement {
  static get observedAttributes() {
    return [
      'name',
      'value',
      'required',
      'disabled',
      'accept',
      'file-name',
      'file-url',
      'max-size',
      'upload-button-class',
      'upload-button-text',
      'error-message-filesize',
      'error-message-required',
      'error-message-upload'
    ];
  }

  get pxNoRerender() {
    return 'children';
  }

  constructor() {
    super();

    this.DEFAULT_MAX_SIZE = '150m';
    this.DEFAULT_TEXTS = {
      'upload-button-text': 'Upload File',
      'error-message-required': 'Please upload a file',
      'error-message-filesize': 'File is too large. Maximum allowed size is {{max_size}}.',
      'error-message-upload': 'Upload failed',
    };
  }

  connectedCallback() {
    this.uploadButton = this.makeUploadButton();
    this.progressIndicator = this.makeProgressIndicator();
    this.filenameInfo = this.makeFilenameInfo();
    this.hiddenInput = this.makeHiddenInput();
    this.fileInput = this.makeFileInput();

    this.setButtonDisabledAttribute();
    this.setButtonValidity();

    this.append(this.uploadButton);
    this.append(this.progressIndicator);
    this.append(this.filenameInfo);
    this.append(this.hiddenInput);
    this.append(this.fileInput);
  }

  disconnectedCallback() {
    this.innerHTML = '';
    this.uploadButton = null;
    this.progressIndicator = null;
    this.filenameInfo = null;
    this.hiddenInput = null;
    this.fileInput = null;
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.isConnected) {
      switch (name) {
      case 'name':
        if (this.hiddenInput) {
          this.hiddenInput.name = newValue;
        }
        break;
      case 'value':
        if (oldValue !== newValue) {
          this.value = newValue;
          this.setButtonDisabledAttribute();
          this.setButtonValidity();
        }
        break;
      case 'required':
        if (oldValue !== newValue) {
          if (newValue === null) {
            this.removeAttribute('required');
          } else {
            this.setAttribute('required', newValue);
          }
          this.setButtonValidity();
        }
        break;
      case 'disabled':
        if (oldValue !== newValue) {
          if (newValue === null) {
            this.removeAttribute('disabled');
          } else {
            this.setAttribute('disabled', newValue);
          }
          this.setButtonDisabledAttribute();
          if (this.hiddenInput) {
            this.hiddenInput.disabled = this.disabled || !this.value;
          }
        }
        break;
      case 'accept':
        if (this.fileInput) {
          if (newValue === null) {
            this.fileInput.removeAttribute('accept');
          } else {
            this.fileInput.setAttribute('accept', newValue);
          }
        }
        break;
      case 'file-name':
        if (this.filenameInfo) {
          this.filenameInfo.textContent = newValue || '';
          if (newValue === null) {
            this.filenameInfo.removeAttribute('download');
            this.filenameInfo.removeAttribute('title');
          } else {
            this.filenameInfo.setAttribute('download', newValue);
            this.filenameInfo.setAttribute('title', newValue);
          }
        }
        break;
      case 'file-url':
        if (this.filenameInfo) {
          if (newValue === null) {
            this.filenameInfo.removeAttribute('href');
          } else {
            this.filenameInfo.setAttribute('href', newValue);
          }
        }
        break;
      case 'upload-button-class':
        if (this.uploadButton) {
          this.uploadButton.className = newValue;
        }
        break;
      case 'upload-button-text':
        if (this.uploadButton) {
          this.uploadButton.innerText = newValue || this.getText('upload-button-text');
        }
        break;
      }
    }
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    if (value === null) {
      if (this.hiddenInput) {
        this.hiddenInput.removeAttribute('value');
        this.hiddenInput.disabled = true;
      }
      this.removeAttribute('value');
    } else {
      if (this.hiddenInput) {
        this.hiddenInput.value = value;
        this.hiddenInput.disabled = this.disabled || !value;
      }
      this.setAttribute('value', value);
    }
  }

  get required() {
    return this.hasAttribute('required');
  }

  set disabled(value) {
    if (value === false) {
      this.removeAttribute('disabled');
    } else {
      this.setAttribute('disabled', '');
    }
  }

  get disabled() {
    return this.hasAttribute('disabled');
  }

  get uploadInProgress() {
    return !!this._uploadInProgress;
  }

  set uploadInProgress(value) {
    this._uploadInProgress = !!value;

    this.setButtonDisabledAttribute();

    if (this.progressIndicator) {
      this.progressIndicator.hidden = !value;
    }
    if (this.filenameInfo) {
      this.filenameInfo.hidden = !!value;
    }
  }

  makeUploadButton() {
    const button = document.createElement('button');

    button.innerText = this.getText('upload-button-text');

    if (this.hasAttribute('upload-button-class')) {
      button.className = this.getAttribute('upload-button-class');
    }

    button.addEventListener('click', (evt) => {
      evt.preventDefault();
      this.fileInput.click();
    });

    return button;
  }

  makeHiddenInput() {
    const input = document.createElement('input');

    input.name = this.getAttribute('name');
    input.type = 'hidden';
    input.value = this.value;
    input.disabled = this.disabled || !this.value;

    input.setAttribute('data-px-no-element-substitutions', 'true');
    input.setAttribute('data-px-no-pricing', 'true');

    return input;
  }

  makeFileInput() {
    const input = document.createElement('input');

    input.type = 'file';
    input.hidden = true;

    if (this.hasAttribute('accept')) {
      input.setAttribute('accept', this.getAttribute('accept'));
    }
    input.addEventListener('change', this.uploadFile.bind(this));
    // Clear the file value upon click so that the change event fires even if the user selects
    // the same file they selected previously.
    input.addEventListener('click', () => input.value = '');

    return input;
  }

  makeProgressIndicator() {
    const div = document.createElement('div');

    div.className = 'px-progress-indicator';
    div.hidden = true;

    return div;
  }

  makeFilenameInfo() {
    const link = document.createElement('a');

    link.className = 'px-filename-info';

    if (this.hasAttribute('file-name')) {
      const filename = this.getAttribute('file-name');
      link.setAttribute('download', filename);
      link.setAttribute('title', filename);
      link.textContent = filename;
    }

    if (this.hasAttribute('file-url')) {
      link.setAttribute('href', this.getAttribute('file-url'));
    }

    return link;
  }

  setButtonDisabledAttribute() {
    if (this.uploadButton) {
      this.uploadButton.disabled = this.disabled || this.uploadInProgress;
    }
  }

  // Button elements support native JS validation (setCustomValidity only).
  // Because we're not using any other visible UI form elements for file upload
  // (the inpu[type=file] is hidden), we set validity message on the button.
  // When ElementInternals is more widely supported, we might want to switch to using it instead:
  // https://developer.mozilla.org/en-US/docs/Web/API/ElementInternals
  setButtonValidity(errmsg) {
    if (this.uploadButton) {
      if (!errmsg) {
        if (this.required && !this.value) {
          errmsg = this.getText('error-message-required');
        }
      }
      this.uploadButton.setCustomValidity(errmsg || '');
    }
  }

  reportButtonValidity() {
    if (this.uploadButton) {
      this.uploadButton.reportValidity();
    }
  }

  getText(key, variables) {
    let text = this.getAttribute(key) || this.DEFAULT_TEXTS[key];

    Object.entries(variables || {}).forEach(([key, value]) => {
      text = text.replaceAll(`{{${key}}}`, value);
    });

    return Px.Util.escapeHTML(text);
  }

  convertMaxSizeToBytes(max_size_str) {
    const match = max_size_str.match(/^(\d+(\.\d+)?)([a-zA-Z]*)?$/);
    const number = parseFloat(match[1]);
    const unit = (match[3] || 'b').toLowerCase();

    let bytes;

    switch (unit) {
    case 'm':
    case 'mb':
      bytes = number * 1024 * 1024;
      break;
    case 'k':
    case 'kb':
      bytes = number * 1024;
      break;
    default:
      bytes = number;
    }

    return bytes;
  }

  uploadFile(evt) {
    evt.stopPropagation();

    const file = evt.target.files[0];

    if (!file) {
      return;
    }

    this.setButtonValidity('');

    const max_size_str = this.getAttribute('max-size') || this.DEFAULT_MAX_SIZE;
    const max_size_bytes = this.convertMaxSizeToBytes(max_size_str);

    if (file.size > max_size_bytes) {
      const errmsg = this.getText('error-message-filesize', {
        max_size: max_size_str
      });
      this.onError(errmsg);
      return;
    }

    this.postFile(file);
  }

  postFile(file) {
    const formData = new FormData();

    formData.append('data', file);

    const xhr = new XMLHttpRequest();

    xhr.addEventListener('loadstart', this.onLoadStart.bind(this), false);
    xhr.addEventListener('loadend', this.onLoadEnd.bind(this), false);
    xhr.upload.addEventListener('progress', this.onProgress.bind(this), false);
    xhr.addEventListener('readystatechange', () => this.onReadyStateChange(xhr), false);

    xhr.open('POST', '/upload/file');
    xhr.send(formData);

    this.uploadInProgress = true;
  }

  onReadyStateChange(xhr) {
    if (xhr.readyState !== 4) {
      return;
    }

    let status = xhr.status ? xhr.status : 0;
    let res;

    try {
      res = JSON.parse(xhr.responseText);
    } catch (e) { }

    if (status >= 400) {
      const errmsg = this.getText('error-message-upload');
      this.onError(errmsg);
    } else {
      if (res) {
        this.onSuccess(res);
      } else {
        if (xhr.responseText) {
          console.error('Upload error', xhr.responseText);
        }

        const errmsg = this.getText('error-message-upload');
        this.onError(errmsg);
      }
    }
  }

  onLoadStart() {
    this.progressIndicator.textContent = '0%';
    this.uploadInProgress = true;
  }

  onProgress(evt) {
    const percent = Math.min(99, Math.round(evt.loaded / evt.total * 100));

    if (percent) {
      this.progressIndicator.textContent = `${percent}%`;
    }
  }

  onLoadEnd() {
    // We set it to 99 instead of 100 because it can still take some time
    // for the upload to get processed after the data has been pushed to the server,
    // and it feels wrong for the progress to be stuck at 100%.
    this.progressIndicator.textContent = '99%';
  }

  onSuccess(response) {
    this.value = `db:${response.id}`;

    this.setAttribute('file-name', response.filename);
    this.setAttribute('file-url', response.url);

    this.uploadInProgress = false;
  }

  onError(errmsg) {
    this.setButtonValidity(errmsg);
    this.reportButtonValidity();

    this.uploadInProgress = false;

    // Reset to default validity.
    setTimeout(() => {
      this.setButtonValidity();
    }, 1000);
  }

};

customElements.define('px-file-upload', Px.CMS.FileUpload);
