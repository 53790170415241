(function($, undefined) {

  function setUpVariants() {
    // Hide and disable all children by default, only display if they are triggered by parent's trigger value
    $('.pxVariantChild').hide();
    $('.pxVariantChild').find('input, select').prop('disabled', true);

    // For select/options
    const selected_option_triggers = $('.pxVariantType.pxVariantHasChildren option:selected');
    $.each(selected_option_triggers, function() {
      const actionTrigger = this.getAttribute('data-trigger');
      if (actionTrigger) {
        $('.pxTrigger-' + actionTrigger).show();
        $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', false);
      }
    });

    // For radio_buttons
    const checked_radio_triggers = $('.pxVariantType.pxVariantHasChildren input[type=radio]:checked');
    $.each(checked_radio_triggers, function() {
      const actionTrigger = this.getAttribute('data-trigger');
      if (actionTrigger) {
        $('.pxTrigger-' + actionTrigger).show();
        $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', false);
      }
    });

    // For checkboxes
    const checked_checkbox_triggers = $('input[type=checkbox]:checked.pxVariantType.pxVariantHasChildren');
    $.each(checked_checkbox_triggers, function() {
      const actionTrigger = this.getAttribute('data-trigger');
      if (actionTrigger) {
        $('.pxTrigger-' + actionTrigger).show();
        $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', false);
      }
    });
  }

  $(document).ready(setUpVariants);
  window.addEventListener('px.fragmentsReloaded', setUpVariants);

  // Select/options live hide/show children
  $(document).on('change', 'select.pxVariantType.pxVariantHasChildren', function(e) {
    const variantId = e.target.getAttribute('data-variantid');
    const actionTrigger = e.target.options[e.target.selectedIndex].getAttribute('data-trigger');

    $('.pxParentVariantId-' + variantId).hide();
    $('.pxParentVariantId-' + variantId).find('input, select').prop('disabled', true);

    if (actionTrigger) {
      $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', false);
      $('.pxTrigger-' + actionTrigger).show();
    }
  });

  // Radio buttons live hide/show children
  $(document).on('change', 'fieldset.pxVariantType.pxVariantHasChildren', function(e) {
    const variantparentid = e.target.getAttribute('data-variantparentid');
    const actionTrigger = e.target.getAttribute('data-trigger');

    $('.pxParentVariantId-' + variantparentid).hide();
    $('.pxParentVariantId-' + variantparentid).find('input, select').prop('disabled', true);

    if (actionTrigger) {
      $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', false);
      $('.pxTrigger-' + actionTrigger).show();
    }
  });

  // Checkboxes live hide/show children
  $(document).on('change', 'input[type=checkbox].pxVariantType.pxVariantHasChildren', function(e) {
    const variantId = e.target.getAttribute('data-variantid');
    const actionTrigger = e.target.getAttribute('data-trigger');

    if (actionTrigger) {
      if ($(e.target).prop('checked')) {
        $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', false);
        $('.pxTrigger-' + actionTrigger).show();
      } else {
        $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', true);
        $('.pxTrigger-' + actionTrigger).hide();
      }
    }
  });


  // Template options

  function setUpTemplateOptions() {
    // Hide all children by defualt, only display if they are triggered by parent's trigger value
    $('.pxTemplateOptionChild').hide();
    $('.pxTemplateOptionChild').find('input, select').prop('disabled', true);

    // For select/options
    const selected_option_triggers = $('.pxTemplateOptionType.pxTemplateOptionHasChildren option:selected');
    $.each(selected_option_triggers, function() {
      const actionTrigger = this.getAttribute('data-trigger');
      if (actionTrigger) {
        $('.pxTrigger-' + actionTrigger).show();
        $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', false);
      }
    });

    // For radio_buttons
    const checked_radio_triggers = $('.pxTemplateOptionType.pxTemplateOptionHasChildren input[type=radio]:checked');
    $.each(checked_radio_triggers, function() {
      const actionTrigger = this.getAttribute('data-trigger');
      if (actionTrigger) {
        $('.pxTrigger-' + actionTrigger).show();
        $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', false);
      }
    });

    // For checkboxes
    const checked_checkbox_triggers = $('input[type=checkbox]:checked.pxTemplateOptionType.pxTemplateOptionHasChildren');
    $.each(checked_checkbox_triggers, function() {
      const actionTrigger = this.getAttribute('data-trigger');
      if (actionTrigger) {
        $('.pxTrigger-' + actionTrigger).show();
        $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', false);
      }
    });
  }

  $(document).ready(setUpTemplateOptions);
  window.addEventListener('px.fragmentsReloaded', setUpTemplateOptions);

  // Select/options live hide/show children
  $(document).on('change', 'select.pxTemplateOptionType.pxTemplateOptionHasChildren', function(e) {
    const variantId = e.target.getAttribute('data-templateoptionid');
    const actionTrigger = e.target.options[e.target.selectedIndex].getAttribute('data-trigger');

    $('.pxParentTemplateOptionId-' + variantId).find('input, select').prop('disabled', true);
    $('.pxParentTemplateOptionId-' + variantId).hide();

    if (actionTrigger) {
      $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', false);
      $('.pxTrigger-' + actionTrigger).show();
    }
  });

  // Radio buttons live hide/show children
  $(document).on('change', 'fieldset.pxTemplateOptionType.pxTemplateOptionHasChildren', function(e) {
    const variantparentid = e.target.getAttribute('data-templateoptionparentid');
    const actionTrigger = e.target.getAttribute('data-trigger');

    $('.pxParentTemplateOptionId-' + variantparentid).find('input, select').prop('disabled', true);
    $('.pxParentTemplateOptionId-' + variantparentid).hide();

    if (actionTrigger) {
      $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', false);
      $('.pxTrigger-' + actionTrigger).show();
    }
  });

  // Checkboxes live hide/show children
  $(document).on('change', 'input[type=checkbox].pxTemplateOptionType.pxTemplateOptionHasChildren', function(e) {
    const variantId = e.target.getAttribute('data-variantid');
    const actionTrigger = e.target.getAttribute('data-trigger');

    if (actionTrigger) {
      if ($(e.target).prop('checked')) {
        $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', false);
        $('.pxTrigger-' + actionTrigger).show();
      } else {
        $('.pxTrigger-' + actionTrigger).find('input, select').prop('disabled', true);
        $('.pxTrigger-' + actionTrigger).hide();
      }
    }
  });

})(jQuery);
