Px.CMS.UploadDialog = class UploadDialog extends HTMLElement {

  constructor() {
    super();

    this.EVENT_TYPES = {
      upload_start: 'upload-start',
      upload_progress: 'upload-progress',
      upload_error: 'upload-error',
      upload_success: 'upload-success',
      close: 'close',
      cancel: 'cancel'
    };

    this.onCancel = this.onCancel.bind(this);

    this.onUploadStart = this.emitEvent.bind(this, this.EVENT_TYPES.upload_start);
    this.onUploadProgress = this.emitEvent.bind(this, this.EVENT_TYPES.upload_progress);
    this.onUploadError = this.emitEvent.bind(this, this.EVENT_TYPES.upload_error);
    this.onUploadSuccess = this.emitEvent.bind(this, this.EVENT_TYPES.upload_success);
  }

  connectedCallback() {
    this.component = Px.CMS.UploadDialogComponent.make(this.componentDataProperties());
    this.component.mount(this);
    this.component.on('destroy', () => this.destroy());
  }

  disconnectedCallback() {
    this.component.destroy();
    this.innerHTML = '';
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.isConnected) {
      this.component.updateData(this.componentDataProperties());
    }
  }

  destroy() {
    this.emitEvent(this.EVENT_TYPES.close);
    this.destroyed = true;
    this.remove();
  }

  componentDataProperties() {
    const props = {
      multiple: this.multiple,
      onCancel: this.onCancel,
      onUploadStart: this.onUploadStart,
      onUploadProgress: this.onUploadProgress,
      onUploadError: this.onUploadError,
      onUploadSuccess: this.onUploadSuccess,
      text_labels: {},
      button_classes: {}
    };

    if (this.hasAttribute('sources')) {
      props.sources = this.getAttribute('sources');
    }

    if (this.hasAttribute('max-size')) {
      props.max_upload_size = this.getAttribute('max-size');
    }
    if (this.hasAttribute('max-files')) {
      props.max_files = parseInt(this.getAttribute('max-files'), 10);
    }

    if (this.hasAttribute('gallery-id')) {
      props.gallery_id = this.getAttribute('gallery-id');
    }

    [
      'error-message-filesize',
      'error-message-upload',
      'back-button-title',
      'close-button-title',
      'main-panel-title',
      'galleries-picker-title',
      'public-galleries-picker-title',
      'url-picker-title',
      'camera-picker-title',
      'source-button-local-text',
      'source-button-galleries-text',
      'source-button-public-galleries-text',
      'source-button-url-text',
      'source-button-camera-text',
      'picker-load-more-button-title',
      'picker-ok-button-title',
      'qr-picker-info-text',
      'no-galleries-text',
      'no-images-text'
    ].forEach(key => {
      if (this.hasAttribute(key)) {
        props.text_labels[key] = this.getAttribute(key);
      }
    });

    [
      'button-class',
      'source-button-class',
      'local-source-button-class',
      'galleries-source-button-class',
      'url-source-button-class',
      'camera-source-button-class',
      'picker-load-more-button-class',
      'confirm-button-class',
      'select-all-button-class'
    ].forEach(key => {
      if (this.hasAttribute(key)) {
        props.button_classes[key] = this.getAttribute(key);
      }
    });

    return props;
  }

  set multiple(value) {
    if (value === false) {
      this.removeAttribute('multiple');
    } else {
      this.setAttribute('multiple', '');
    }
  }

  get multiple() {
    return this.hasAttribute('multiple');
  }

  onCancel() {
    this.emitEvent(this.EVENT_TYPES.cancel);
    this.destroy();
  }

  emitEvent(event_type, payload) {
    if (this.destroyed) {
      return;
    }

    const options = {};
    if (payload) {
      options.detail = payload;
    }

    const event = new CustomEvent(event_type, options);
    return this.dispatchEvent(event);
  }

};

customElements.define('px-upload-dialog', Px.CMS.UploadDialog);
