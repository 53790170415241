Px.CMS.ProjectPreview = class ProjectPreview extends Px.CMS.PreviewBase {

  static get observedAttributes() {
    return super.observedAttributes.concat([
      'project-id',
      'page-number',
      'preview-section',
      'src'  // deprecated
    ]);
  }

  connectedCallback() {
    if (this.getAttribute('src')) {
      return this.deprecatedConnectedCallback();
    }
    super.connectedCallback();
  }

  previewUrlBase(format) {
    const project_id = this.getAttribute('project-id');
    if (!project_id) {
      return '';
    }
    return `/v1/books/${project_id}/preview.${format}`;
  }

  attributeChangedCallback() {
    if (this.getAttribute('src')) {
      return;
    }
    super.attributeChangedCallback();
  }

  previewUrlParams() {
    const params = super.previewUrlParams();
    const page_number = this.getAttribute('page-number');
    if (page_number) {
      params.page = parseInt(page_number, 10) - 1;
    }
    const preview_section = this.getAttribute('preview-section');
    if (preview_section) {
      params.preview_section = preview_section;
    }
    return params;
  }

  // ------------------------------------------------------------------------
  // Old deprecated methods that we only support for backwards compatibility.

  deprecatedConnectedCallback() {
    const title = this.getAttribute('title') || '';

    let width = this.getAttribute('width');
    let height = this.getAttribute('height');
    if (width && !height) {
      height = width;
    } else if (height && !width) {
      width = height;
    } else {
      width = height = 150;
    }
    this.width = parseFloat(width);
    this.height = parseFloat(height);

    const url = new URL(this.getAttribute('src'), window.location.origin);
    const params = new URLSearchParams(url.query);
    const pixel_ratio = window.devicePixelRatio || 1;
    params.set('width', this.width * pixel_ratio);
    params.set('height', this.height * pixel_ratio);
    url.search = params.toString();
    const preview_src = url.toString();

    if (preview_src.match('mapped_preview.html')) {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', preview_src);
      iframe.setAttribute('title', title);
      this.shadowRoot.append(iframe);
    } else {
      const img = document.createElement('img');
      img.setAttribute('src', preview_src);
      img.setAttribute('alt', title);
      this.shadowRoot.append(img);
    }

    this.deprecatedSetStyle();
  }

  deprecatedSetStyle() {
    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.innerHTML = `
      :host {
        display: flex;
        width: ${this.width}px;
        height: ${this.height}px;
      }
      iframe {
        border: none;
        padding: 0;
        width: 100%;
        height: height: 100%;
      }
      img {
        display: block;
        border: none;
        padding: 0;
        margin: 0;
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
      }
    `;
    this.shadowRoot.append(style);
  }

};

customElements.define('px-project-preview', Px.CMS.ProjectPreview);
