Px.CMS.ProductPrice = class ProductPrice extends HTMLElement {

  static get observedAttributes() {
    return [
      'initial',
      'product-id',
      'design-id',
      'unit-price',
      'formatting-settings',
      'option-selector'
    ];
  }

  constructor() {
    super();
    this._update_timeout = null;
    this._fetch_controller = new AbortController();
    this._last_used_url = '';
    this.attachShadow({mode: 'open'});
    this.globalChangeHandler = this.globalChangeHandler.bind(this);
    this.handleFragmentsReloaded = this.handleFragmentsReloaded.bind(this);
  }

  connectedCallback() {
    document.addEventListener('change', this.globalChangeHandler);
    window.addEventListener('px.fragmentsReloaded', this.handleFragmentsReloaded);
    this.scheduleUpdate();
  }

  disconnectedCallback() {
    document.removeEventListener('change', this.globalChangeHandler);
    window.removeEventListener('px.fragmentsReloaded', this.handleFragmentsReloaded);
  }

  attributeChangedCallback() {
    this.scheduleUpdate();
  }

  handleFragmentsReloaded() {
    this._last_used_url = '';
  }

  scheduleUpdate() {
    clearTimeout(this._update_timeout);
    this._update_timeout = setTimeout(() => this.updatePrice(), 0);
  }

  initialContent() {
    return this.getAttribute('initial') || '';
  }

  globalChangeHandler(evt) {
    for (const observed_selector of this.observedOptionSelectors()) {
      if (evt.target === observed_selector) {
        this.scheduleUpdate();
        return;
      }
    }
  }

  observedOptionSelectors() {
    if (!this.hasAttribute('option-selector')) {
      // Default to all <px-option-selector> elements on the page.
      return document.getElementsByTagName('px-option-selector');
    }

    const ids = this.getAttribute('option-selector').split(' ').map(id => id.trim()).filter(id => id);
    const option_selectors = [];

    for (const id of ids) {
      if (id) {
        const option_selector = document.getElementById(id);
        if (option_selector) {
          option_selectors.push(option_selector);
        }
      }
    }

    return option_selectors;
  }

  priceQueryParameters() {
    const query = {};

    const print_theme_id = this.getAttribute('design-id');
    if (print_theme_id) {
      query.print_theme_id = print_theme_id;
    }

    for (const option_selector of this.observedOptionSelectors()) {
      Object.assign(query, option_selector.values({skipInvalid: true, skipNoPricing: true}));
    }

    if (query['book[pages]'] && !query['pages']) {
      query['pages'] = query['book[pages]'];
    }

    return query;
  }

  productPriceURL() {
    const product_id = this.getAttribute('product-id');
    if (!product_id) {
      return null;
    }
    const query = new URLSearchParams(this.priceQueryParameters());
    return `/v1/products/${product_id}/price_forecast.json?${query}`;
  }

  formatPrice(price) {
    const options = JSON.parse(this.getAttribute('formatting-settings') || '{}');
    return Px.Util.formatCurrency(price, options);
  }

  async updatePrice() {
    const url = this.productPriceURL();
    if (url === this._last_used_url) {
      return;
    }

    this._last_used_url = url;
    this._fetch_controller.abort();
    this._fetch_controller = new AbortController();

    if (url === null) {
      this.shadowRoot.innerHTML = this.initialContent();
      return;
    }

    this.style.opacity = 0.25;

    let request;
    try {
      request = await fetch(url, {signal: this._fetch_controller.signal});
    } catch (err) {
      if (err.name === 'AbortError') {
        // Ignore, we abort requests on purpose.
        return;
      } else {
        throw err;
      }
    }

    const response = await request.json();
    let price = response.price;
    if (this.getAttribute('unit-price') !== 'true') {
      price *= parseInt(this.priceQueryParameters()['quantity'] || 1, 10);
    }
    this.shadowRoot.innerHTML = this.formatPrice(price);
    this.style.opacity = 1;
  }

};

customElements.define('px-product-price', Px.CMS.ProductPrice);
