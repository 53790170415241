Px.CMS.Carousel = class Carousel extends Px.Component {

  template() {
    return Px.template`
      <div class="px-carousel" data-onmouseenter="stopSlideshow" data-onmouseleave="maybeStartSlideshow">
        <div class="px-slides">
          <ol class="px-layout-hack">
            ${this.data.slides.map(slide => {
              return Px.template`
                <li class="px-slide">
                  ${Px.raw(slide)}
                </li>
              `;
            })}
          </ol>
          <ol class="px-slide-display">
            ${this.data.slides.map((slide, idx) => {
              return Px.template`
                <li class="px-slide" data-active="${idx === this.state.active_slide_idx}">
                  ${Px.raw(slide)}
                </li>
              `;
            })}
          </ol>
          <div class="px-navigation">
            <button class="px-prev" data-onclick="goToPrev">
              Previous
            </button>
            <button class="px-next" data-onclick="goToNext">
              Next
            </button>
          </div>
        </div>
        <ol class="px-pager">
          ${this.data.slides.map((slide, idx) => {
            return Px.template`
              <li class="px-pager-item" data-active="${idx === this.state.active_slide_idx}">
                <a data-onclick="goToSlide" data-idx="${idx}">
                  ${idx + 1}
                </a>
              </li>
            `;
          })}
        </ol>
      </div>
    `;
  }

  get dataProperties() {
    return {
      slides: {std: []},
      autoplay: {std: true},
      slide_duration: {std: 5000}
    };
  }

  static get properties() {
    return {
      active_slide_idx: {type: 'int', std: 0}
    };
  }

  constructor(props) {
    super(props);
    this.maybeStartSlideshow();
  }

  maybeStartSlideshow() {
    if (this.data.autoplay && !this._slideshow_interval) {
      this.startSlideshow();
    }
  }

  startSlideshow() {
    this._slideshow_interval = setInterval(this.goToNext.bind(this), this.data.slide_duration);
  }

  stopSlideshow() {
    clearInterval(this._slideshow_interval);
    this._slideshow_interval = null;
  }

  // --------------
  // Event handlers
  // --------------

  goToPrev() {
    const active_idx = this.state.active_slide_idx;
    let prev_idx = (active_idx === 0 ? this.data.slides.length : active_idx) - 1;
    this.state.active_slide_idx = prev_idx;
  }

  goToNext() {
    const active_idx = this.state.active_slide_idx;
    let next_idx = active_idx === this.data.slides.length - 1 ? 0 : active_idx + 1;
    this.state.active_slide_idx = next_idx;
  }

  goToSlide(evt) {
    const idx = parseInt(evt.target.getAttribute('data-idx'), 10);
    this.state.active_slide_idx = idx;
  }

};
