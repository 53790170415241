Px.CMS.ThemeFilters = {
  updateCheckbox: function(element, options) {
    options = options || {};
    const value = element.value;
    let url = new URL(window.location);
    const name = element.getAttribute('name');
    const param_name = name + '[]';
    const filter_wrapper = Px.CMS.ThemeFilters.getFilterWrapper(element);
    const checkboxes = filter_wrapper.querySelectorAll(`input[type="checkbox"][name="${name}"]`);
    url.searchParams.delete(param_name);
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        url.searchParams.append(param_name, checkbox.value);
      }
    });
    // This is required to make sure *no* checkboxes end up being checked,
    // including checkboxes which are otherwise checked by default.
    if (!url.searchParams.has(param_name)) {
      url.searchParams.append(param_name, '');
    }
    if (options.url_filter) {
      url = options.url_filter(url);
    }
    Px.CMS.ThemeFilters.pushFilterChange(url.toString(), element, options);
  },

  updateRadioButton: function(element, options) {
    options = options || {};
    const value = element.value;
    let url = new URL(window.location);
    const name = element.getAttribute('name');
    const param_name = name + '[]';
    if (element.checked) {
      url.searchParams.set(param_name, element.value);
    } else {
      url.searchParams.delete(param_name);
    }
    if (options.url_filter) {
      url = options.url_filter(url);
    }
    Px.CMS.ThemeFilters.pushFilterChange(url.toString(), element, options);
  },

  updateSelect: function(element, options) {
    options = options || {};
    const value = element.value;
    let url = new URL(window.location);
    const name = element.getAttribute('name');
    const param_name = name + '[]';
    url.searchParams.set(param_name, element.value);
    if (options.url_filter) {
      url = options.url_filter(url);
    }
    Px.CMS.ThemeFilters.pushFilterChange(url.toString(), element, options);
  },

  pushFilterChange: function(url, element, options) {
    if (options.selectors) {
      options.selectors.forEach(selector => {
        const element = document.querySelector(selector);
        if (element) {
          element.classList.add('px-live-fragment-loading');
        }
      });
    } else {
      const wrapper = Px.CMS.ThemeFilters.getFilterWrapper(element);
      wrapper.classList.add('px-live-fragment-loading');
    }
    const state = {};
    if (options.selectors) {
      state.selectors = options.selectors;
    }
    history.pushState(state, '', url);
    Px.CMS.filterState = {pathname: location.pathname, search: location.search};
    Px.CMS.fetchAndMorph(options);
  },

  getFilterWrapper: function(element) {
    return element.closest('[data-px-filter]') || document.body;
  }
};


Px.CMS.filterState = {pathname: location.pathname, search: location.search};

// If current state is a result of fragment reload, make sure to undo it on popstate.
window.addEventListener('popstate', evt => {
  // We can't really tell for sure whether fetchAndMorph is required, but filters will always change
  // the search params (and never the pathname), so run fetchAndMorph whenever search params change,
  // but pathname does not. Also ignore changes to location.hash only.
  if (Px.CMS.filterState.pathname === location.pathname && Px.CMS.filterState.search !== location.search) {
    Px.CMS.fetchAndMorph(evt.state || {});
    Px.CMS.filterState = {pathname: location.pathname, search: location.search};
  }
});
