Px.CMS.PagePreview = class PagePreview extends Px.CMS.PreviewBase {

  static get observedAttributes() {
    return super.observedAttributes.concat([
      'page-id'
    ]);
  }

  previewUrlBase(format) {
    const page_id = this.getAttribute('page-id');
    if (!page_id) {
      return '';
    }
    return `/v1/pages/${page_id}.${format}`;
  }

};

customElements.define('px-page-preview', Px.CMS.PagePreview);
