Px.CMS.DesignPreview = class DesignPreview extends Px.CMS.PreviewBase {

  static get observedAttributes() {
    return super.observedAttributes.concat([
      'design-id',
      'product-id',
      'template-name'
    ]);
  }

  previewUrlBase(format) {
    const design_id = this.getAttribute('design-id');
    if (!design_id) {
      return '';
    }
    return `/v1/themes/${design_id}/preview.${format}`;
  }

  previewUrlParams() {
    const params = super.previewUrlParams();
    const product_id = this.getAttribute('product-id');
    if (product_id) {
      params.product_id = product_id;
    }
    const template_name = this.getAttribute('template-name');
    if (template_name) {
      params.template_name = template_name;
    }
    return params;
  }

  observedOptionSelectors() {
    // Default to all <px-option-selector> elements on the page.
    if (!this.hasAttribute('option-selector')) {
      return document.getElementsByTagName('px-option-selector');
    }
    return super.observedOptionSelectors();
  }

};

customElements.define('px-design-preview', Px.CMS.DesignPreview);
