Px.CMS.Helpers = {

  // This parses image values as used by image substitutions and the <px-image-upload> component
  // into a dict with `image_id` and (maybe) `crop_data` properties.
  // Examples:
  // 'db:123'                   => {image_id: '123'}
  // 'db:999@{l:3.33,z:24,r:90} => {image_id: '999', crop_data: {l: 3.33, z: 24, r: 90}}
  parseImageValue: function(value) {
    const parts = value.split('@');
    const value_dict = {};
    if (parts[0]) {
      const image_id = parts[0].split(':')[1];
      value_dict.image_id = image_id;
    }
    if (parts[1]) {
      const match = parts[1].trim().match(/^\{(.*)\}$/);
      if (match) {
        const crop_data = {};
        match[1].split(',').map((p) => p.trim()).forEach((part) => {
          const [key, val] = part.split(':').map((p) => p.trim());
          switch (key) {
          case 'l':
            crop_data.l = parseFloat(val);
            break;
          case 't':
            crop_data.t = parseFloat(val);
            break;
          case 'z':
            crop_data.z = parseFloat(val);
            break;
          case 'r':
            crop_data.r = parseFloat(val);
            break;
          }
        });
        value_dict.crop_data = crop_data;
      }
    }
    return value_dict;
  },

  // Takes a crop string such as 1/3, 2.5/8.1, 1.5, 200mm/300mm and converts it to a float.
  parseCropAspectRatioString: function(crop_ar_str) {
    if (!crop_ar_str) {
      return null;
    };

    const crop_aspect_ratio_parts = crop_ar_str.split('/', 2).map(n => parseFloat(n));
    const crop_aspect_ratio = crop_aspect_ratio_parts[0] / (crop_aspect_ratio_parts[1] || 1);

    if (isFinite(crop_aspect_ratio) && crop_aspect_ratio > 0) {
      return crop_aspect_ratio;
    } else {
      return null;
    }
  },

  isLowResolution: function(image_pixel_width, image_pixel_height, crop_data, crop_ar_str, minimum_dpi, rotation_mode) {
    if (!(minimum_dpi && image_pixel_width && image_pixel_height && crop_ar_str)) {
      return false;
    }
    if (!(crop_ar_str.includes('in') || crop_ar_str.includes('mm'))) {
      return false;
    }

    const dims_in_inches = crop_ar_str.split('/', 2).map(part => {
      part = part.trim();
      let number = parseFloat(part);
      if (!part.endsWith('in')) {
        number = Px.Util.mm2in(number);
      }
      return number;
    });

    const crop_inch_width = dims_in_inches[0];
    const crop_inch_height = dims_in_inches.length > 1 ? dims_in_inches[1] : width;
    const zoom = 1 + (crop_data.z / 100);
    const rotation = crop_data.r;
    const is_shrink_to_fit_mode = crop_data.z < 0;
    const flip_crop_box = Math.abs(crop_data.r) % 180 === 90 && rotation_mode === 'placeholder';

    let xres, yres;
    if (is_shrink_to_fit_mode) {
      const image_ar = image_pixel_width / image_pixel_height;
      const image_inch_dims = Px.Util.inscribedRectangleDimensions(
        crop_inch_width, crop_inch_height, rotation, image_ar
      );
      xres = (image_pixel_width / image_inch_dims.width);
      yres = (image_pixel_height / image_inch_dims.height);
    } else {
      let crop_ar_float = Px.CMS.Helpers.parseCropAspectRatioString(crop_ar_str);
      const crop_pixel_dims = Px.Util.inscribedRectangleDimensions(
        image_pixel_width, image_pixel_height, rotation, crop_ar_float
      );
      xres = (crop_pixel_dims.width / crop_inch_width) / zoom;
      yres = (crop_pixel_dims.height / crop_inch_height) / zoom;
    }
    return xres < minimum_dpi || yres < minimum_dpi;
  }

};
