Px.CMS.ImageAdjustTool = class ImageAdjustTool extends HTMLElement {

  static get observedAttributes() {
    return [
      'value',
      'img-src',
      'img-width',
      'img-height',
      'crop-aspect-ratio',
      'shrink-to-fit'
    ];
  }

  get pxNoRerender() {
    return 'children';
  }

  connectedCallback() {
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.component = Px.CMS.ImageAdjustToolComponent.make(this.componentDataProperties());
    this.component.mount(this);
  }

  disconnectedCallback() {
    this.component.destroy();
    this.innerHTML = '';
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.isConnected) {
      switch (name) {
      case 'value':
        if (oldValue !== newValue) {
          this.value = newValue;
        }
      default:
        this.component.updateData(this.componentDataProperties());
      }
    }
  }

  get value() {
    return this.getAttribute('value');
  }

  set value(value) {
    if (value === null) {
      this.removeAttribute('value');
    } else {
      this.setAttribute('value', value);
    }
  }

  componentDataProperties() {
    const data = {
      value: this.getAttribute('value'),
      image_src: this.getAttribute('img-src'),
      shrink_to_fit_enabled: this.hasAttribute('shrink-to-fit'),
      onCancel: this.onCancel,
      onSubmit: this.onSubmit
    };

    if (this.hasAttribute('img-width')) {
      data.image_width = parseInt(this.getAttribute('img-width'), 10);
    }
    if (this.hasAttribute('img-height')) {
      data.image_height = parseInt(this.getAttribute('img-height'), 10);
    }
    if (this.hasAttribute('minimum-dpi')) {
      data.minimum_dpi = parseFloat(this.getAttribute('minimum-dpi'));
    }

    [
      'crop_aspect_ratio',
      'rotation_mode',
      'rotate_button_text',
      'shrink_to_fit_button_text',
      'cancel_button_text',
      'submit_button_text',
      'button_class',
      'edit_button_class',
      'rotate_button_class',
      'shrink_to_fit_button_class',
      'cancel_button_class',
      'submit_button_class'
    ].forEach(property => {
      const attr = property.replaceAll('_', '-');
      if (this.hasAttribute(attr)) {
        data[property] = this.getAttribute(attr);
      }
    });

    return data;
  }

  onCancel() {
    const event = new CustomEvent('cancel', {bubbles: true});
    return this.dispatchEvent(event);
  }

  onSubmit(value) {
    this.value = value;
    const event = new CustomEvent('confirm', {bubbles: true});
    return this.dispatchEvent(event);
  }

};

customElements.define('px-image-adjust-tool', Px.CMS.ImageAdjustTool);
