Px.CMS.MappedPreviews = {

  initPreview: function(image_node) {
    let component;

    const generateMappedPreview = () => {
      image_node.classList.add('px-live-fragment-loading');
      const data = JSON.parse(image_node.getAttribute('data-px-mapped-preview'));
      if (component) {
        component.destroy();
      }
      component = Px.Components.MappedPreview.make({
        width: data.width,
        height: data.height,
        bg_url: data.bg_url,
        glb_url: data.glb_url,
        preview_sections: data.preview_sections,
        onRender: () => {
          if (component.loaded) {
            requestAnimationFrame(() => {
              component.toBlob(blob => {
                const url = URL.createObjectURL(blob);
                image_node.addEventListener('load', () => URL.revokeObjectURL(url));
                image_node.src = url;
                image_node.classList.remove('px-live-fragment-loading');
              });
            });
          }
        }
      });
      const div = document.createElement('div');
      component.mount(div);
    };

    generateMappedPreview();

    const mutation_observer = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        if (mutation.attributeName === 'data-px-mapped-preview') {
          generateMappedPreview();
        }
      }
    });
    mutation_observer.observe(image_node, {attributes: true});
  }

};
