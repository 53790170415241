Px.CMS.ImageAdjustToolComponent = class ImageAdjustToolComponent extends Px.Component {

  template() {
    const r = this.renderChild;
    return Px.template`
      <div class="px-image-adjust-tool">
        ${r(Px.Components.ResizeDetector, 'resize-detector', {onResize: this.resize})}
        <div class="px-crop-container">
          <img class="px-crop-target"
               src="${this.data.image_src}"
               style="${this.imageStyle}"
          />
          ${Px.if(this.cropAspectRatio, () => {
            return Px.template`
              <div class="px-crop-box"
                  style="${this.cropBoxStyle}"
                  data-onmousedown="${this.cropBoxDragHandler}"
                  data-ontouchstart="${this.cropBoxDragHandler}">
                ${Px.if(!this.isShrinkToFitMode, () => {
                  return Px.template`
                    ${['t', 'tr', 'r', 'br', 'b', 'bl', 'l', 'tl'].map(position => {
                      return Px.template`
                        <div class="px-crop-box-shadow"
                            data-position="${position}"
                            style="${this.cropBoxShadowStyle(position)}">
                        </div>
                      `;
                    })}
                    ${['nw', 'n', 'ne', 'e', 'se', 's', 'sw', 'w'].map(position => {
                      return Px.template`
                        <div class="px-crop-box-handle"
                            data-position="${position}"
                            data-onmousedown="${this.cropBoxResizeHandler}"
                            data-ontouchstart="${this.cropBoxResizeHandler}">
                        </div>
                      `;
                    })}
                  `;
                })}
                ${Px.if(this.isLowResolution, () => {
                  return Px.template`
                    <div class="px-resolution-warning">
                      ${Px.raw(ImageAdjustToolComponent.icons.resolution_warning)}
                    </div>
                  `;
                })}
              </div>
            `;
          })}
        </div>
        <div class="px-buttons-container">
          <div class="px-edit-buttons-container">
            <button class="${this.rotateButtonClass}" data-onclick="rotateRight">
              ${Px.raw(ImageAdjustToolComponent.icons.rotate)}
              ${this.data.rotate_button_text}
            </button>
            ${Px.if(this.data.shrink_to_fit_enabled && this.cropAspectRatio, () => {
              return Px.template`
                <button class="${this.shrinkToFitButtonClass}"
                        data-pressed="${this.isShrinkToFitMode}"
                        data-onclick="toggleShrinkToFit">
                  ${Px.raw(ImageAdjustToolComponent.icons.shrink_to_fit)}
                  ${this.data.shrink_to_fit_button_text}
                </button>
              `;
            })}
            <button class="${this.resetButtonClass}" data-onclick="resetCropData">
              ${Px.raw(ImageAdjustToolComponent.icons.reset)}
              ${this.data.reset_button_text}
            </button>
          </div>
          <div class="px-action-buttons-container">
            <button class="${this.cancelButtonClass}" data-onclick="onCancel">
              ${this.data.cancel_button_text}
            </button>
            <button class="${this.submitButtonClass}" data-onclick="onSubmit">
              ${this.data.submit_button_text}
            </button>
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this._resize_scheduled = false;
    this._drag_raf = null;
    this._resize_raf = null;

    this.resize = this.resize.bind(this);
    this.startCropBoxDrag = this.startCropBoxDrag.bind(this);
    this.dragCropBox = this.dragCropBox.bind(this);
    this.endCropBoxDrag = this.endCropBoxDrag.bind(this);
    this.startCropBoxResize = this.startCropBoxResize.bind(this);
    this.resizeCropBox = this.resizeCropBox.bind(this);
    this.endCropBoxResize = this.endCropBoxResize.bind(this);

    // We cannot bind touch events with `passive: false` using standard Component event handling mechanism,
    // so we manually (re)bind them here on each update.
    // We have to use `passive: false` because otherwise page scrolling messes with cropping.
    const registerPassiveTouchHandlers = () => {
      Array.from(this.dom_node.querySelectorAll('[data-ontouchstart]')).forEach(node => {
        const handler = this[node.getAttribute('data-ontouchstart')];
        node.removeEventListener('touchstart', handler);
        node.addEventListener('touchstart', handler, {passive: false});
      });
    };

    this.on('mount', () => {
      registerPassiveTouchHandlers();
      this.resize();
    });

    this.on('update', () => registerPassiveTouchHandlers);

    this.registerReaction(() => this.data.image_src, src => {
      const img = new Image();
      img.onload = () => {
        mobx.runInAction(() => {
          this.state.image_width = img.naturalWidth;
          this.state.image_height = img.naturalHeight;
        });
      };
      img.src = src;
    }, {
      fireImmediately: true,
      name: 'Px.CMS.ImageAdjustToolComponent::MeasureImageDimensions'
    });

    this.registerReaction(() => [this.data.value, this.data.image_src, this.data.crop_aspect_ratio], () => {
      this.reinitCropState();
    }, {
      equals: mobx.comparer.structural,
      name: 'Px.CMS.ImageAdjustToolComponent::ResetCropState'
    });

    this.reinitCropState();
  }

  get dataProperties() {
    return {
      value: {type: 'str'},
      image_src: {type: 'str'},
      image_width: {std: null},
      image_height: {std: null},
      default_crop_data: {std: null},
      crop_aspect_ratio: {std: null},
      shrink_to_fit_enabled: {std: false},
      rotation_mode: {std: 'image'},
      rotate_button_text: {std: 'Rotate'},
      shrink_to_fit_button_text: {std: 'Shrink to fit'},
      reset_button_text: {std: 'Reset'},
      cancel_button_text: {std: 'Cancel'},
      submit_button_text: {std: 'Submit'},
      button_class: {std: null},
      edit_button_class: {std: null},
      rotate_button_class: {std: null},
      shrink_to_fit_button_class: {std: null},
      reset_button_class: {std: null},
      minimum_dpi: {std: null},
      action_button_class: {std: null},
      cancel_button_class: {std: null},
      submit_button_class: {std: null},
      onChange: {std: () => {}},
      onCancel: {std: () => {}},
      onSubmit: {std: () => {}}
    };
  }

  static get properties() {
    return {
      crop_data: {type: 'obj', std: null},
      image_width: {type: 'int', std: 0},
      image_height: {type: 'int', std: 0},
      container_width: {type: 'int', std: 0},
      container_height: {type: 'int', std: 0},
      drag_data: {type: 'obj', std: null}
    };
  }

  static get computedProperties() {
    return {
      parsedValue: function() {
        const value_dict = Px.CMS.Helpers.parseImageValue(this.data.value);
        // Swap `l` and `t` if using placeholder rotation and placeholder is currently rotated.
        const crop_data = value_dict.crop_data;
        if (crop_data && this.data.rotation_mode === 'placeholder') {
          const rotation = crop_data.r || 0;
          if (rotation % 180 === 90) {
            const l = crop_data.l;
            const t = crop_data.t;
            crop_data.l = t || 0;
            crop_data.t = -l || 0;
          }
        }
        return value_dict;
      },
      isShrinkToFitMode: function() {
        return this.state.crop_data.z < 0;
      },
      cropAspectRatio: function() {
        const crop_aspect_ratio = Px.CMS.Helpers.parseCropAspectRatioString(this.data.crop_aspect_ratio);
        if (crop_aspect_ratio === null) {
          return null;
        }
        if (this.flipCropBox) {
          return 1 / crop_aspect_ratio;
        } else {
          return crop_aspect_ratio;
        }
      },
      isLowResolution: function() {
        return Px.CMS.Helpers.isLowResolution(
          this.data.image_width,
          this.data.image_height,
          this.state.crop_data,
          this.data.crop_aspect_ratio,
          this.data.minimum_dpi,
          this.data.rotation_mode
        );
      },
      zoom: function() {
        return 1 + (this.state.crop_data.z / 100);
      },
      flip: function() {
        return Math.abs(this.state.crop_data.r) % 180 === 90;
      },
      flipImage: function() {
        return this.flip && this.data.rotation_mode === 'image';
      },
      flipCropBox: function() {
        return this.flip && this.data.rotation_mode === 'placeholder';
      },
      imageDimensions: function() {
        const width = this.data.image_width || this.state.image_width;
        const height = this.data.image_height || this.state.image_height;
        return {
          width: this.flipImage ? height : width,
          height: this.flipImage ? width: height
        };
      },
      imageAspectRatio: function() {
        return this.imageDimensions.width / this.imageDimensions.height;
      },
      imageElementDimensions: function() {
        const dimensions = {};
        if ((this.imageDimensions.width / this.state.container_width) >
            (this.imageDimensions.height / this.state.container_height)) {
          dimensions.width = this.state.container_width;
          dimensions.height = dimensions.width / this.imageAspectRatio;
        } else {
          dimensions.height = this.state.container_height;
          dimensions.width = dimensions.height * this.imageAspectRatio;
        }
        return dimensions;
      },
      imageStyle: function() {
        // TODO: When we implement the "straighten" function, this will have to be rounded
        //       to the nearest 90, and the rest implemented differently.
        const rotation = this.data.rotation_mode === 'image' ? this.state.crop_data.r : 0;
        const img_dims = this.imageElementDimensions;
        const width = this.flipImage ? img_dims.height : img_dims.width;
        const height = this.flipImage ? img_dims.width : img_dims.height;
        const scale = this.zoom < 1 ? this.zoom : 1;
        return `transform: rotate(${rotation}deg); width: ${width}px; height: ${height}px; scale: ${scale};`;
      },
      cropBoxDimensions: function() {
        const img_dims = this.imageElementDimensions;
        let width, height;
        if (img_dims.width / this.cropAspectRatio > img_dims.height) {
          height = img_dims.height;
          width = height * this.cropAspectRatio;
        } else {
          width = img_dims.width;
          height = width / this.cropAspectRatio;
        }
        if (this.zoom > 1) {
          width = width / this.zoom;
          height = height / this.zoom;
        }
        return {width, height};
      },
      cropBoxTranslation: function() {
        const img_dims = this.imageElementDimensions;
        const crop_data = this.state.crop_data;
        let tx = -(this.flip ? img_dims.height : img_dims.width) * crop_data.l / 100;
        let ty = -(this.flip ? img_dims.width : img_dims.height) * crop_data.t / 100;
        switch (crop_data.r) {
        case 180:
        case -180:
          [tx, ty] = [-tx, -ty];
          break;
        case 90:
          [tx, ty] = [-ty, tx];
          break;
        case -90:
          [tx, ty] = [ty, -tx];
          break;
        }
        return {
          x: tx,
          y: ty
        };
      },
      cropBoxStyle: function() {
        const width = this.cropBoxDimensions.width;
        const height = this.cropBoxDimensions.height;
        const translation = this.cropBoxTranslation;
        return `width: ${width}px; height: ${height}px; transform: translate(${translation.x}px, ${translation.y}px);`;
      },
      cropBoxDragHandler: function() {
        return this.isShrinkToFitMode ? '' : 'startCropBoxDrag';
      },
      cropBoxResizeHandler: function() {
        return this.isShrinkToFitMode ? '' : 'startCropBoxResize';
      },
      rotateButtonClass: function() {
        const classes = [];
        if (this.data.button_class) {
          classes.push(this.data.button_class);
        }
        if (this.data.edit_button_class) {
          classes.push(this.data.edit_button_class);
        }
        if (this.data.rotate_button_class) {
          classes.push(this.data.rotate_button_class);
        }
        return classes.join(' ');
      },
      shrinkToFitButtonClass: function() {
        const classes = [];
        if (this.data.button_class) {
          classes.push(this.data.button_class);
        }
        if (this.data.edit_button_class) {
          classes.push(this.data.edit_button_class);
        }
        if (this.data.shrink_to_fit_button_class) {
          classes.push(this.data.shrink_to_fit_button_class);
        }
        return classes.join(' ');
      },
      resetButtonClass: function() {
        const classes = [];
        if (this.data.button_class) {
          classes.push(this.data.button_class);
        }
        if (this.data.edit_button_class) {
          classes.push(this.data.edit_button_class);
        }
        if (this.data.reset_button_class) {
          classes.push(this.data.reset_button_class);
        }
        return classes.join(' ');
      },
      cancelButtonClass: function() {
        const classes = [];
        if (this.data.button_class) {
          classes.push(this.data.button_class);
        }
        if (this.data.action_button_class) {
          classes.push(this.data.action_button_class);
        }
        if (this.data.cancel_button_class) {
          classes.push(this.data.cancel_button_class);
        }
        return classes.join(' ');
      },
      submitButtonClass: function() {
        const classes = [];
        if (this.data.button_class) {
          classes.push(this.data.button_class);
        }
        if (this.data.action_button_class) {
          classes.push(this.data.action_button_class);
        }
        if (this.data.submit_button_class) {
          classes.push(this.data.submit_button_class);
        }
        return classes.join(' ');
      }
    };
  }

  cropBoxShadowStyle(position) {
    const iw = this.imageElementDimensions.width;
    const cw = this.cropBoxDimensions.width;
    const ih = this.imageElementDimensions.height;
    const ch = this.cropBoxDimensions.height;
    const dw = iw - cw;
    const dh = ih - ch;
    const tx = this.cropBoxTranslation.x;
    const ty = this.cropBoxTranslation.y;

    let width, height;
    switch (position) {
    case 't':
      width = cw;
      height = dh/2 + ty;
      break;
    case 'tr':
      width = dw/2 - tx;
      height = dh/2 + ty;
      break;
    case 'r':
      width = dw/2 - tx;
      height = ch;
      break;
    case 'br':
      width = dw/2 - tx;
      height = dh/2 - ty;
      break;
    case 'b':
      width = cw;
      height = dh/2 - ty;
      break;
    case 'bl':
      width = dw/2 + tx;
      height = dh/2 - ty;
      break;
    case 'l':
      width = dw/2 + tx;
      height = ch;
      break;
    case 'tl':
      width = dw/2 + tx;
      height = dh/2 + ty;
      break;
    }

    return `width: ${width}px; height: ${height}px`;
  }

  updateCropData(updates) {
    let changed = false;

    mobx.runInAction(() => {
      Object.keys(updates).forEach(key => {
        const value = updates[key];
        if (this.state.crop_data[key] !== value) {
          this.state.crop_data[key] = value;
          changed = true;
        }
      });
    });

    if (changed) {
      this.onChange();
    }
  }

  reinitCropState() {
    const crop_data = Object.assign({l: 0, t: 0, r: 0, z: 0}, this.parsedValue.crop_data);

    if (this.state.crop_data) {
      this.updateCropData(crop_data);
    } else {
      this.state.crop_data = crop_data;
    }
  }

  serializeValue(dict) {
    let value = '';
    if (dict.image_id) {
      value = `db:${dict.image_id}`;
      if (dict.crop_data) {
        let crop_parts = [];
        ['l', 't', 'r', 'z'].forEach((key) => {
          let val = dict.crop_data[key];
          // Swap `l` and `t` if we're using placeholder rotation and placeholder is rotated.
          if (this.flipCropBox) {
            if (key === 'l') {
              val = dict.crop_data.t;
              if (val) {
                val = -val;
              }
            } else if (key === 't') {
              val = dict.crop_data.l;
            }
          }
          if (val) {
            crop_parts.push(`${key}:${val}`);
          }
        });
        if (crop_parts.length > 0) {
          value += `@{${crop_parts.join(',')}}`;
        }
      }
    }
    return value;
  }

  // --------------
  // Event handlers
  // --------------

  resize() {
    if (this._resize_scheduled) {
      // Resize handler already scheduled, abort.
      return;
    } else {
      this._resize_scheduled = true;
    }
    requestAnimationFrame(() => {
      const container = this.dom_node.querySelector('.px-crop-container');
      const computed_style = getComputedStyle(container);
      const container_width = container.clientWidth -
            (parseFloat(computed_style.paddingLeft) + parseFloat(computed_style.paddingRight));
      const container_height = container.clientHeight -
            (parseFloat(computed_style.paddingTop) + parseFloat(computed_style.paddingBottom));
      mobx.runInAction(() => {
        this.state.container_width = container_width;
        this.state.container_height = container_height;
      });
      this._resize_scheduled = false;
    });
  }

  rotateRight(evt) {
    let rotation = this.state.crop_data.r + 90;
    rotation = Px.Util.roundToNearest(rotation, 90);
    // In placeholder rotation mode, pressing the rotate button
    // should always result in rotation being either 0 or 90, nothing else.
    if (this.data.rotation_mode === 'placeholder') {
      if (rotation > 90) {
        rotation = 0;
      }
    } else if (rotation > 180) {
      rotation = rotation - 360;
    }
    this.updateCropData({
      r: rotation,
      l: 0,
      t: 0,
      z: 0
    });
  }

  toggleShrinkToFit(evt) {
    if (this.isShrinkToFitMode) {
      this.resetCropData();
      return;
    }

    const image_w = this.data.image_width || this.state.image_width;
    const image_h = this.data.image_height || this.state.image_height;
    const image_ar = image_w / image_h;
    const crop_ar = this.flipCropBox ? 1 / this.cropAspectRatio : this.cropAspectRatio;
    const need_to_flip = (image_ar > 1 && crop_ar < 1) || (image_ar < 1 && crop_ar > 1);

    let rotation = 0;
    let zoom = 0;
    let fit_ar = crop_ar;
    if (need_to_flip) {
      rotation = 90;
      fit_ar = 1 / crop_ar;
    }
    if (image_ar > fit_ar) {
      const max_h = (image_w / fit_ar);
      zoom = 100 * ((image_h / max_h) - 1);
    } else {
      const max_w = (fit_ar * image_h);
      zoom = 100 * ((image_w / max_w) - 1);
    }

    this.updateCropData({
      l: 0,
      t: 0,
      r: rotation,
      z: zoom
    });
  }

  resetCropData(evt) {
    let default_crop_data = {l: 0, t: 0, r: 0, z: 0};

    if (this.data.default_crop_data) {
      const image_id = this.parsedValue.image_id;
      const crop_data = this.data.default_crop_data;
      const value_dict = Px.CMS.Helpers.parseImageValue(`${image_id}@${crop_data}`);
      default_crop_data = Object.assign(default_crop_data, value_dict.crop_data);
    }

    this.updateCropData(default_crop_data);
  }

  startCropBoxDrag(evt) {
    if (evt.type === 'mousedown' && evt.button !== 0) {
      return;
    }
    if (evt.type === 'touchstart' && evt.touches.length !== 1) {
      return;
    }

    evt.preventDefault();
    evt.stopPropagation();

    const image_element = this.dom_node.querySelector('.px-crop-target');
    const crop_box_element = this.dom_node.querySelector('.px-crop-box');

    this.state.drag_data = {
      origin: {
        page_x: evt.type === 'touchstart' ? evt.touches[0].pageX : evt.pageX,
        page_y: evt.type === 'touchstart' ? evt.touches[0].pageY : evt.pageY,
        crop_box: crop_box_element.getBoundingClientRect()
      },
      image_box: image_element.getBoundingClientRect()
    };

    if (evt.type === 'mousedown') {
      document.addEventListener('mousemove', this.dragCropBox);
      document.addEventListener('mouseup', this.endCropBoxDrag);
    } else {
      document.addEventListener('touchmove', this.dragCropBox, {passive: false});
      document.addEventListener('touchend', this.endCropBoxDrag);
      document.addEventListener('touchcancel', this.endCropBoxDrag);
    }
  }

  dragCropBox(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    if (this._drag_raf) {
      return;
    }

    this._drag_raf = requestAnimationFrame(() => {
      const pageX = evt.type === 'touchmove' ? evt.touches[0].pageX : evt.pageX;
      const pageY = evt.type === 'touchmove' ? evt.touches[0].pageY : evt.pageY;

      const drag_data = this.state.drag_data;

      const dl = pageX - drag_data.origin.page_x;
      const dt = pageY - drag_data.origin.page_y;

      const cbl = drag_data.origin.crop_box.left;
      const cbt = drag_data.origin.crop_box.top;
      const cbw = drag_data.origin.crop_box.width;
      const cbh = drag_data.origin.crop_box.height;

      const ibl = drag_data.image_box.left;
      const ibt = drag_data.image_box.top;
      const ibw = drag_data.image_box.width;
      const ibh = drag_data.image_box.height;

      const center_x = ibl + (ibw / 2);
      const center_y = ibt + (ibh / 2);

      const new_left = cbl + dl;
      const clipped_left = Math.min(Math.max(new_left, ibl), ibl + ibw - cbw);

      const new_top = cbt + dt;
      const clipped_top = Math.min(Math.max(new_top, ibt), ibt + ibh - cbh);

      let l = 100 * (center_x - (clipped_left + cbw/2)) / ibw;
      let t = 100 * (center_y - (clipped_top + cbh/2)) / ibh;

      switch (this.state.crop_data.r) {
      case 180:
      case -180:
        [l, t] = [-l, -t];
        break;
      case 90:
        [l, t] = [t, -l];
        break;
      case -90:
        [l, t] = [-t, l];
        break;
      }

      mobx.runInAction(() => {
        drag_data.origin.page_x += (new_left - clipped_left);
        drag_data.origin.page_y += (new_top - clipped_top);
        this.updateCropData({
          l: l,
          t: t
        });
      });

      this._drag_raf = null;
    });
  }

  endCropBoxDrag(evt) {
    cancelAnimationFrame(this._drag_raf);
    this._drag_raf = null;
    this.state.drag_data = {};
    document.removeEventListener('mousemove', this.dragCropBox);
    document.removeEventListener('mouseup', this.endCropBoxDrag);
    document.removeEventListener('touchmove', this.dragCropBox);
    document.removeEventListener('touchend', this.endCropBoxDrag);
    document.removeEventListener('touchcancel', this.endCropBoxDrag);
  }

  startCropBoxResize(evt) {
    if (evt.type === 'mousedown' && evt.button !== 0) {
      return;
    }
    if (evt.type === 'touchstart' && evt.touches.length !== 1) {
      return;
    }

    evt.stopPropagation();
    evt.preventDefault();

    const image_element = this.dom_node.querySelector('.px-crop-target');
    const crop_box_element = this.dom_node.querySelector('.px-crop-box');

    this.state.drag_data = {
      origin: {
        page_x: evt.type === 'touchstart' ? evt.touches[0].pageX : evt.pageX,
        page_y: evt.type === 'touchstart' ? evt.touches[0].pageY : evt.pageY,
        crop_box: crop_box_element.getBoundingClientRect()
      },
      handle_position: evt.target.dataset.position,
      image_box: image_element.getBoundingClientRect()
    };

    if (evt.type === 'mousedown') {
      document.addEventListener('mousemove', this.resizeCropBox);
      document.addEventListener('mouseup', this.endCropBoxResize);
    } else {
      document.addEventListener('touchmove', this.resizeCropBox, {passive: false});
      document.addEventListener('touchend', this.endCropBoxResize);
      document.addEventListener('touchcancel', this.endCropBoxResize);
    }
  }

  resizeCropBox(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    if (this._resize_raf) {
      return;
    }

    this._resize_raf = requestAnimationFrame(() => {
      const page_x = evt.type === 'touchmove' ? evt.touches[0].pageX : evt.pageX;
      const page_y = evt.type === 'touchmove' ? evt.touches[0].pageY : evt.pageY;

      const drag_data = this.state.drag_data;

      const dl = page_x - drag_data.origin.page_x;
      const dt = page_y - drag_data.origin.page_y;

      const cbl = drag_data.origin.crop_box.left;
      const cbt = drag_data.origin.crop_box.top;
      const cbw = drag_data.origin.crop_box.width;
      const cbh = drag_data.origin.crop_box.height;
      const cbr = cbl + cbw;
      const cbb = cbt + cbh;

      const ibl = drag_data.image_box.left;
      const ibt = drag_data.image_box.top;
      const ibw = drag_data.image_box.width;
      const ibh = drag_data.image_box.height;
      const ibr = ibl + ibw;
      const ibb = ibt + ibh;

      const center_x = ibl + (ibw / 2);
      const center_y = ibt + (ibh / 2);

      // Max zoom is 200 (equivalent to 1/3 of the shorter side).
      let min_width, min_height;
      if (ibw / ibh < this.cropAspectRatio) {
        min_width = ibw / 3;
        min_height = min_width / this.cropAspectRatio;
      } else {
        min_height = ibh / 3;
        min_width = min_height * this.cropAspectRatio;
      }

      let new_cbl = cbl;
      let new_cbt = cbt;
      let new_cbr = cbr;
      let new_cbb = cbb;

      let new_width, new_height;
      let max_width, max_height;
      let clipped_width, clipped_height;

      const clip_dimensions = (width, height, min_w, max_w, min_h, max_h) => {
        width = Math.max(min_w, Math.min(max_w, width));
        height = Math.max(min_h, Math.min(max_h, height));

        if (width / height < this.cropAspectRatio) {
          height = width / this.cropAspectRatio;
        } else {
          width = height * this.cropAspectRatio;
        }

        return [width, height];
      };

      switch (drag_data.handle_position) {
      case 'nw':
        new_width = cbw - dl;
        new_height = cbh - dt;
        max_width = cbr - ibl;
        max_height = cbb - ibt;
        [clipped_width, clipped_height] = clip_dimensions(
          new_width, new_height, min_width, max_width, min_height, max_height
        );
        new_cbl = cbr - clipped_width;
        new_cbt = cbb - clipped_height;
        break;
      case 'n':
        new_height = cbh - dt;
        new_width = new_height * this.cropAspectRatio;
        max_width = 2 * Math.min(cbl - ibl + cbw/2, ibr - cbr + cbw/2);
        max_height = cbb - ibt;
        [clipped_width, clipped_height] = clip_dimensions(
          new_width, new_height, min_width, max_width, min_height, max_height
        );
        new_cbl = cbl + (cbw - clipped_width) / 2;
        new_cbt = cbb - clipped_height;
        break;
      case 'ne':
        new_width = cbw + dl;
        new_height = cbh - dt;
        max_width = ibr - cbl;
        max_height = cbb - ibt;
        [clipped_width, clipped_height] = clip_dimensions(
          new_width, new_height, min_width, max_width, min_height, max_height
        );
        new_cbt = cbb - clipped_height;
        break;
      case 'e':
        new_width = cbw + dl;
        new_height = new_width / this.cropAspectRatio;
        max_width = ibr - cbl;
        max_height = 2 * Math.min(cbt - ibt + cbh/2, ibb - cbb + cbh/2);
        [clipped_width, clipped_height] = clip_dimensions(
          new_width, new_height, min_width, max_width, min_height, max_height
        );
        new_cbt = cbt + (cbh - clipped_height) / 2;
        break;
      case 'se':
        new_width = cbw + dl;
        new_height = cbh + dt;
        max_width = ibr - cbl;
        max_height = ibb - cbt;
        [clipped_width, clipped_height] = clip_dimensions(
          new_width, new_height, min_width, max_width, min_height, max_height
        );
        break;
      case 's':
        new_height = cbh + dt;
        new_width = new_height * this.cropAspectRatio;
        max_width = 2 * Math.min(cbl - ibl + cbw/2, ibr - cbr + cbw/2);
        max_height = ibb - cbt;
        [clipped_width, clipped_height] = clip_dimensions(
          new_width, new_height, min_width, max_width, min_height, max_height
        );
        new_cbl = cbl + (cbw - clipped_width) / 2;
        break;
      case 'sw':
        new_width = cbw - dl;
        new_height = cbh + dt;
        max_width = cbr - ibl;
        max_height = ibb - cbt;
        [clipped_width, clipped_height] = clip_dimensions(
          new_width, new_height, min_width, max_width, min_height, max_height
        );
        new_cbl = cbr - clipped_width;
        break;
      case 'w':
        new_width = cbw - dl;
        new_height = new_width / this.cropAspectRatio;
        max_width = cbr - ibl;
        max_height = 2 * Math.min(cbt - ibt + cbh/2, ibb - cbb + cbh/2);
        [clipped_width, clipped_height] = clip_dimensions(
          new_width, new_height, min_width, max_width, min_height, max_height
        );
        new_cbl = cbr - clipped_width;
        new_cbt = cbt + (cbh - clipped_height) / 2;
        break;
      }

      let l = 100 * (center_x - (new_cbl + clipped_width/2)) / ibw;
      let t = 100 * (center_y - (new_cbt + clipped_height/2)) / ibh;

      switch (this.state.crop_data.r) {
      case 180:
      case -180:
        [l, t] = [-l, -t];
        break;
      case 90:
        [l, t] = [t, -l];
        break;
      case -90:
        [l, t] = [-t, l];
        break;
      }

      this.updateCropData({
        z: Math.max(0, (Math.min(ibw / clipped_width, ibh / clipped_height) - 1) * 100),
        l: l,
        t: t
      });

      this._resize_raf = null;
    });
  }

  endCropBoxResize(evt) {
    cancelAnimationFrame(this._resize_raf);
    this._resize_raf = null;
    this.state.drag_data = {};
    document.removeEventListener('mousemove', this.resizeCropBox);
    document.removeEventListener('mouseup', this.endCropBoxResize);
    document.removeEventListener('touchmove', this.resizeCropBox);
    document.removeEventListener('touchend', this.endCropBoxResize);
    document.removeEventListener('touchcancel', this.endCropBoxResize);
  }

  onChange() {
    const value = this.serializeValue({image_id: this.parsedValue.image_id, crop_data: this.state.crop_data});
    this.data.onChange(value);
  }

  onCancel(evt) {
    this.reinitCropState();
    this.data.onCancel();
  }

  onSubmit(evt) {
    const value = this.serializeValue({image_id: this.parsedValue.image_id, crop_data: this.state.crop_data});
    this.data.onSubmit(value);
  }

};

Px.CMS.ImageAdjustToolComponent.icons = {
  rotate: '<svg width="18" height="18" viewBox="0 0 27 27" fill="none"><path d="M11.25 26.5C9.6875 26.5 8.22417 26.2033 6.86 25.61C5.49584 25.0167 4.30834 24.2146 3.2975 23.2038C2.28667 22.1929 1.48417 21.0054 0.890002 19.6413C0.295835 18.2771 -0.000831583 16.8133 1.7507e-06 15.25C1.7507e-06 12.125 1.09375 9.46875 3.28125 7.28125C5.46875 5.09375 8.125 4 11.25 4H11.4375L9.5 2.0625L11.25 0.25L16.25 5.25L11.25 10.25L9.5 8.4375L11.4375 6.5H11.25C8.8125 6.5 6.745 7.34917 5.0475 9.0475C3.35 10.7458 2.50084 12.8133 2.5 15.25C2.5 17.6875 3.34917 19.7554 5.0475 21.4537C6.74584 23.1521 8.81334 24.0008 11.25 24C11.9792 24 12.6979 23.9113 13.4063 23.7338C14.1146 23.5563 14.7917 23.2908 15.4375 22.9375L17.25 24.75C16.3542 25.3333 15.3958 25.7708 14.375 26.0625C13.3542 26.3542 12.3125 26.5 11.25 26.5ZM18.75 22.75L11.25 15.25L18.75 7.75L26.25 15.25L18.75 22.75Z" fill="currentColor"/></svg>',
  shrink_to_fit: '<svg width="20" height="16.5" viewBox="0 0 17 14" fill="none"><path d="M15.167 4.33334V1.83334H12.667V0.166677H15.167C15.6253 0.166677 16.0178 0.330011 16.3445 0.656677C16.6712 0.983344 16.8342 1.37557 16.8337 1.83334V4.33334H15.167ZM0.166992 4.33334V1.83334C0.166992 1.37501 0.330326 0.982788 0.656992 0.656677C0.983659 0.330566 1.37588 0.167233 1.83366 0.166677H4.33366V1.83334H1.83366V4.33334H0.166992ZM12.667 13.5V11.8333H15.167V9.33334H16.8337V11.8333C16.8337 12.2917 16.6706 12.6842 16.3445 13.0108C16.0184 13.3375 15.6259 13.5006 15.167 13.5H12.667ZM1.83366 13.5C1.37533 13.5 0.983103 13.337 0.656992 13.0108C0.330881 12.6847 0.167548 12.2922 0.166992 11.8333V9.33334H1.83366V11.8333H4.33366V13.5H1.83366ZM3.50033 10.1667V3.50001H13.5003V10.1667H3.50033ZM5.16699 8.50001H11.8337V5.16668H5.16699V8.50001Z" fill="currentColor"/></svg>',
  reset: '<svg height="16" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" fill="currentColor"/></svg>',
  resolution_warning: '<svg width="24" height="23" viewBox="-4 -4 32 31"><rect x="-4" y="-4" width="100%" height="100%" rx="10" ry="10" fill-opacity="0.6" fill="#fff" /><line stroke-width="2" stroke="#fff" x1="12" y1="3" x2="12" y2="18"/><path d="M10.03 3.50618C10.886 2.02268 13.111 2.02268 13.967 3.50618L21.713 16.9238C22.543 18.3613 21.458 20.1246 19.744 20.1246H4.25401C2.53901 20.1246 1.45401 18.3613 2.28401 16.9238L10.03 3.50714V3.50618ZM12.997 16.2913C13.0012 16.1631 12.9786 16.0354 12.9303 15.9157C12.882 15.7961 12.8091 15.687 12.716 15.5948C12.6229 15.5027 12.5114 15.4295 12.3881 15.3794C12.2648 15.3294 12.1324 15.3036 11.9985 15.3036C11.8647 15.3036 11.7322 15.3294 11.6089 15.3794C11.4857 15.4295 11.3742 15.5027 11.281 15.5948C11.1879 15.687 11.115 15.7961 11.0667 15.9157C11.0185 16.0354 10.9958 16.1631 11 16.2913C11.0082 16.5398 11.117 16.7756 11.3034 16.9486C11.4897 17.1216 11.739 17.2184 11.9985 17.2184C12.258 17.2184 12.5073 17.1216 12.6936 16.9486C12.88 16.7756 12.9888 16.5398 12.997 16.2913ZM12.738 8.76551C12.7121 8.58502 12.6156 8.4207 12.4681 8.30577C12.3206 8.19085 12.1331 8.1339 11.9434 8.14644C11.7537 8.15897 11.5761 8.24007 11.4465 8.37332C11.3168 8.50657 11.2448 8.68203 11.245 8.86422L11.249 13.1777L11.256 13.2754C11.2819 13.4559 11.3784 13.6202 11.5259 13.7352C11.6734 13.8501 11.861 13.907 12.0506 13.8945C12.2403 13.882 12.4179 13.8009 12.5476 13.6676C12.6772 13.5344 12.7492 13.3589 12.749 13.1767L12.745 8.86231L12.738 8.76551Z" fill="currentColor"/></svg>',
};
