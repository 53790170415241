Px.CMS.DateSelector = class DateSelector extends HTMLElement {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
  }

  get pxNoRerender() {
    return 'children';
  }

  getDefaultDateFromProps() {
    const defaultDateFromProps = this.getAttribute('start-date');

    if (defaultDateFromProps) {
      return new Date(Date.parse(defaultDateFromProps));
    }
  }

  getDefaultDate() {
    // Return the first of next month;
    const currentDate = new Date();

    if (currentDate.getMonth() === 11) {
      return new Date(currentDate.getFullYear() + 1, 0, 1);
    }

    return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  }

  getDefaultYearByDefaultMonth(defaultMonth, defaultDay = 1) {
    const DEFAULT_YEAR_SHIFT_DAY_DIFF = 90;
    const todayTime = Date.now();
    const today = new Date(todayTime);
    const defaultYearShiftMsDiff = DEFAULT_YEAR_SHIFT_DAY_DIFF * 24 * 60 * 60 * 1000;

    let closestDate = null;

    for (let i of [-1, 0, 1]) {
      const year = new Date(today.getFullYear() + i, defaultMonth - 1, defaultDay);

      if (
        closestDate === null || Math.abs(year.getTime() - todayTime) <
          Math.abs(closestDate.getTime() - todayTime)
      ) {
        closestDate = year;
      }
    }

    if (todayTime - closestDate.getTime() >= defaultYearShiftMsDiff) {
      return closestDate.getFullYear() + 1;
    }

    return closestDate.getFullYear();
  }

  onChange() {
    const daySelectorElement = this.daySelectorElement();

    if (daySelectorElement) {
      daySelectorElement.rebuildDateOptions();
    }
  }

  yearSelectorElement() {
    return this.querySelector('px-date-year-selector');
  }

  monthSelectorElement() {
    return this.querySelector('px-date-month-selector');
  }

  daySelectorElement() {
    return this.querySelector('px-date-day-selector');
  }
};

Px.CMS.DateSelector.YearSelector = class YearSelector extends HTMLElement {
  connectedCallback() {
    const defaultValue = this.getDefaultValue();
    const select = document.createElement('select');

    select.setAttribute('name', this.getAttribute('name'));
    // We use the custom element's class for backwards-compatibility only. You should use select-class instead.
    select.className = this.hasAttribute('select-class') ? this.getAttribute('select-class') : this.className;
    select.addEventListener('change', this.dateSelectorElement().onChange);

    this.selectElement = select;

    this.getYears().forEach(year => {
      const option = document.createElement('option');
      option.value = year;
      option.innerText = year;

      if (year === defaultValue) {
        option.selected = true;
      }

      select.append(option);
    });

    this.append(select);
  }

  dateSelectorElement() {
    return this.closest('px-date-selector');
  }

  getDefaultValue() {
    const defaultDateFromProps = this.dateSelectorElement().getDefaultDateFromProps();

    if (defaultDateFromProps) {
      return defaultDateFromProps.getFullYear();
    }

    const defaultYear = this.getAttribute('default');

    if (defaultYear) {
      return +defaultYear;
    }

    const monthSelectorElement = this.dateSelectorElement().monthSelectorElement();

    if (monthSelectorElement) {
      const defaultMonthFromProps = monthSelectorElement.getAttribute('default');

      if (defaultMonthFromProps) {
        return this.dateSelectorElement().getDefaultYearByDefaultMonth(defaultMonthFromProps);
      }
    }

    const defaultDate = this.dateSelectorElement().getDefaultDate();
    return defaultDate.getFullYear();
  }

  getMinValue() {
    const defaultValue = this.getDefaultValue();
    const minSubtractorFromProps = this.getAttribute('min');
    const subtractor = minSubtractorFromProps || -5;

    return defaultValue + +subtractor;
  }

  getMaxValue() {
    const defaultValue = this.getDefaultValue();
    const maxSubtractorFromProps = this.getAttribute('max');
    const subtractor = maxSubtractorFromProps || 5;

    return defaultValue + +subtractor;
  }

  getYears() {
    const startYear = this.getMinValue();
    const endYear = this.getMaxValue();
    const result = [];

    for (let year = startYear; year <= endYear; year++) {
      result.push(year);
    }

    return result;
  }

  setValue(value) {
    if (!this.selectElement) {
      return;
    }

    const optionsList = this.selectElement.options;

    if (!optionsList.length) {
      return;
    }

    Array.from(optionsList).forEach(option => {
      option.selected = false;

      if (+option.value === value) {
        option.selected = true;
      }
    });
  }
};

Px.CMS.DateSelector.MonthSelector = class MonthSelector extends HTMLElement {
  connectedCallback() {
    const defaultValue = this.getDefaultValue();
    const monthNames = (this.getAttribute('month-names') || '').split(' ').map(m => m.trim());
    const select = document.createElement('select');

    select.setAttribute('name', this.getAttribute('name'));
    // We use the custom element's class for backwards-compatibility only. You should use select-class instead.
    select.className = this.hasAttribute('select-class') ? this.getAttribute('select-class') : this.className;
    select.addEventListener('change', this.dateSelectorElement().onChange);

    this.selectElement = select;

    [1,2,3,4,5,6,7,8,9,10,11,12].forEach(month => {
      const option = document.createElement('option');

      option.value = month;
      option.innerText = monthNames[month - 1] || month;

      if (month === defaultValue) {
        option.selected = true;
      }

      select.append(option);
    });

    this.append(select);

    const defaultMonth = this.getDefaultValueFromProps();
    const yearSelectorElement = this.dateSelectorElement().yearSelectorElement();

    if (yearSelectorElement && defaultMonth) {
      yearSelectorElement.setValue(
        this.dateSelectorElement().getDefaultYearByDefaultMonth(defaultMonth)
      );
    }
  }

  getDefaultValueFromProps() {
    return this.getAttribute('default');
  }

  getDefaultValue() {
    const defaultDateFromProps = this.dateSelectorElement().getDefaultDateFromProps();

    if (defaultDateFromProps) {
      return defaultDateFromProps.getMonth() + 1;
    }

    const defaultMonth = this.getDefaultValueFromProps();

    if (defaultMonth) {
      return +defaultMonth;
    }

    const defaultDate = this.dateSelectorElement().getDefaultDate();

    return defaultDate.getMonth() + 1;
  }

  dateSelectorElement() {
    return this.closest('px-date-selector');
  }
};

Px.CMS.DateSelector.DaySelector = class DaySelector extends HTMLElement {
  connectedCallback() {
    const defaultValue = this.getDefaultValue();
    const select = document.createElement('select');

    select.setAttribute('name', this.getAttribute('name'));
    // We use the custom element's class for backwards-compatibility only. You should use select-class instead.
    select.className = this.hasAttribute('select-class') ? this.getAttribute('select-class') : this.className;
    select.addEventListener('change', this.dateSelectorElement().onChange);

    this.append(select);
    this.selectElement = select;

    this.rebuildDateOptions(defaultValue);

    requestAnimationFrame(() => this.rebuildDateOptions(defaultValue));
  }

  rebuildDateOptions(selectedDay) {
    const type = this.getAttribute('type') === 'weekly' ? 'weekly' : 'daily';

    let weekdayStart = 1;

    if (this.getAttribute('weekday')) {
      weekdayStart = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'].indexOf(this.getAttribute('weekday'));
    }

    selectedDay = selectedDay || parseInt(this.selectElement.value);

    let selectedMonth, selectedYear;
    const pxSelectMonth = this.dateSelectorElement().monthSelectorElement();
    const pxSelectYear = this.dateSelectorElement().yearSelectorElement();
    const defaultDateFromProps = this.dateSelectorElement().getDefaultDateFromProps();
    const defaultDate = this.dateSelectorElement().getDefaultDate();

    if (pxSelectMonth && pxSelectMonth.selectElement && pxSelectMonth.selectElement.value) {
      selectedMonth = pxSelectMonth.selectElement.value;
    } else if (defaultDateFromProps) {
      selectedMonth = defaultDateFromProps.getMonth();
    } else {
      selectedMonth = defaultDate.getMonth();
    }

    if (pxSelectYear && pxSelectYear.selectElement && pxSelectYear.selectElement.value) {
      selectedYear = pxSelectYear.selectElement.value;
    } else if (defaultDateFromProps) {
      selectedYear = defaultDateFromProps.getFullYear();
    } else {
      selectedYear = defaultDate.getFullYear();
    }

    const daysInMonth = new Date(Date.UTC(selectedYear, selectedMonth, 0)).getUTCDate();
    const days = [];

    for (let i = 0; i < daysInMonth; i++) {
      const weekday = new Date(Date.UTC(selectedYear, selectedMonth - 1, i + 1)).getUTCDay();

      if (type === 'daily' || weekday === weekdayStart) {
        days.push(i + 1);
      }
    }

    this.selectElement.innerHTML = '';

    const newSelectedDay = days.includes(selectedDay) ? selectedDay : days[0];

    days.forEach(d => {
      const option = document.createElement('option');

      option.value = d;
      option.selected = newSelectedDay === d;
      option.innerHTML = d;

      this.selectElement.appendChild(option);
    });
  }

  getDefaultValue() {
    const defaultDateFromProps = this.dateSelectorElement().getDefaultDateFromProps();

    if (defaultDateFromProps) {
      return defaultDateFromProps.getDate();
    }

    const defaultDay = this.getAttribute('default');

    if (defaultDay) {
      return +defaultDay;
    }

    const defaultDate = this.dateSelectorElement().getDefaultDate();

    return defaultDate.getDate();
  }

  dateSelectorElement() {
    return this.closest('px-date-selector');
  }
};

customElements.define('px-date-selector', Px.CMS.DateSelector);
customElements.define('px-date-year-selector', Px.CMS.DateSelector.YearSelector);
customElements.define('px-date-month-selector', Px.CMS.DateSelector.MonthSelector);
customElements.define('px-date-day-selector', Px.CMS.DateSelector.DaySelector);
